import React        from "react"
import {NewsLayout} from "sections/News/"
import useNewsData  from "shared/Hooks/News/useNewsData"
import {Images}     from "src/sections/News/NewsLayout/Images";
import {TextBlock}  from "src/sections/News/NewsLayout/TextBlock";
import {NEWS_5}     from "types/News"

const Article5 = () => {
  const data = useNewsData(NEWS_5);
  return (
    <NewsLayout data={data}>
      <TextBlock data={data.texts.first}/>
      <Images data={data.images.first}/>
      <TextBlock data={data.texts.second}/>
      <Images data={data.images.second}/>
      <TextBlock data={data.texts.third}/>
    </NewsLayout>
  )
}

export default Article5
